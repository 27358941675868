import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {

  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const howRef = useRef(null);
  const whyRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>vso dev</title>
        <meta property="og:title" content="vso dev" />
      </Helmet>
      <section ref={aboutRef} className="home-get-started">
        <div className="home-header-container">
          <div className="home-header">
            <h2 className="home-heading">
              At VSODEV, we&apos;ve already developed software for hundreds of
              businesses and students in launching their dream ventures online.
            </h2>
            <p className="home-caption">
              <span>
                Take the next step towards realizing your vision with our
                software development services. With just one click, transform
                your ideas into reality and stay ahead in the digital landscape!
              </span>
              <br></br>
            </p>
          </div>
          <div className="home-button">
            <button className="button">
            <a  href = "https://m.me/vsodevtech" >Get started today</a>
            </button>
          </div>
        </div>
        <img alt="image" src="/vso.png" className="home-image" />
      </section>
      <section  ref={servicesRef}  className="home-statistics">
        <div  className="home-content">
          <div className="home-stat">
            <h3 className="home-header01">Mobile App</h3>
            <span className="home-caption1">
              We pride ourselves on delivering exceptional work without breaking
              the bank. Our services combine quality and affordability to ensure
              your project&apos;s success without straining your budget.
            </span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header02">Web App</h3>
            <span className="home-caption2">
              We provide cutting-edge tools and a top-of-the-line tech stack
              that aligns with industry standards and surpasses market
              expectations
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header03">Desktop App</h3>
            <span className="home-caption3">
              Over the years, we&apos;ve had the privilege of serving a diverse
              clientele, exceeding a hundred satisfied customers.
            </span>
          </div>
        </div>
      </section>
      <section  className="home-objectives">
        <div  className="home-content01">
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text03">Consult</h3>
              <p className="home-text04">
                we listen attentively to your needs and goals, crafting tailored
                strategies to guide our development process.
              </p>
            </div>
            <div className="objective home-objective1">
              <h3 className="home-text05">Develop</h3>
              <p className="home-text06">
                Our skilled team then embarks on the journey of creating
                cutting-edge solutions, meticulously developing every aspect to
                perfection. With a keen eye for detail and innovation, we ensure
                that your project meets and exceeds expectations.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text07">Deploy</h3>
              <p className="home-text08">
                Our seamless deployment process ensures a smooth transition into
                operation, empowering you to embrace the full potential of your
                online dream.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref={whyRef} className="home-growth">
        <div   className="home-content02">
          <div className="home-header04">
            <div className="home-header05">
              <h2 className="home-heading1">
                <span>WE USE THE BEST IN THE MARKET</span>
                <br></br>
              </h2>
            </div>
            <p className="home-caption4">
              ReactJS and React Native have been the best language and framework
              for cross-platform application development in the market for
              years.
            </p>
            <p className="home-caption5">
              <br className="home-text11"></br>
              <br></br>
              <span>
                React stands out as the premier choice for developing
                applications across all platforms due to its versatility,
                efficiency, and robust ecosystem. Its component-based
                architecture promotes reusability and modular development,
                enabling developers to build scalable applications effortlessly
              </span>
            </p>
          </div>
          <div className="home-testimonial">
            <div className="home-content03">
              <span className="home-text14">Testimonial</span>
              <p className="home-text15">“sold out na sya sa takbo.”</p>
            </div>
            <div className="home-information">
              <div className="home-author">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjcxMDU2MzMz&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon"
                />
                <span className="home-name">
                  <span className="home-text16">Joeffrey Solangoy</span>
                  <span className="home-text17"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-images">
          <div className="home-square"></div>
          <img
            alt="image"
            src="https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png"
            className="home-image1"
          />
        </div>
      </section>
      <section className="home-experience">
        <div className="home-images1">
          <div className="home-square1"></div>
          <img
            alt="image"
            src="/experience-1400w.png"
            className="home-image2"
          />
        </div>
        <div className="home-content04">
          <div className="home-header06">
            <div className="home-header-container1">
              <div className="home-header07">
                <h2 className="home-heading2">
                  <span>A worlds class </span>
                  <span className="home-text19">application</span>
                </h2>
              </div>
              <p className="home-caption6">
                We follow the standard for excellence in design, functionality,
                and user experience.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-checkmarks">
              <div className="home-check">
                <div className="home-mark">
                  <svg viewBox="0 0 1024 1024" className="home-icon01">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text20">UI and UX Design</span>
              </div>
              <div className="home-check1">
                <div className="home-mark1">
                  <svg viewBox="0 0 1024 1024" className="home-icon03">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text21">Quality Analysis</span>
              </div>
              <div className="home-check2">
                <div className="home-mark2">
                  <svg viewBox="0 0 1024 1024" className="home-icon05">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text22">
                  Continuous Integration and Deployment
                </span>
              </div>
              <div className="home-check3">
                <div className="home-mark3">
                  <svg viewBox="0 0 1024 1024" className="home-icon07">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text23">
                  Highly Optimized app for uninterrupted user experience
                </span>
              </div>
            </div>
          </div>
          <div className="home-testimonial1">
            <div className="home-content05">
              <span className="home-text24">Testimonial</span>
              <p className="home-text25">
                “Smooth ng website, mabilis maintindihan”
              </p>
            </div>
            <div className="home-information1">
              <div className="home-author1">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY3MTA1NjMzMw&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon09"
                />
                <span className="home-name1">Cory Tiangco</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-create">
        <div className="home-content06">
          <div className="home-header08">
            <h2 className="home-heading3">
              <span>Your dream website is just a click away.</span>
              <br></br>
            </h2>
          </div>
          <button onClick = {'https'}className="home-button2 button">
            <a href = "https://m.me/vsodevtech" >
              <span>Get started today</span>
              <br></br>
            </a>
          </button>
        </div>
      </section>
      <section  ref={howRef}  className="home-faq">
        <div  className="home-header09">
          <h2 className="home-heading4">
            <span>Frequently asked </span>
            <span className="home-text32">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </h2>
        </div>
        <div className="home-content07">
          <div className="home-column">
            <div className="home-element">
              <h3 className="home-header10">
                How much would application development cost?
              </h3>
              <p className="home-content08">
                Depending on your requirement, price starts at lowest it can be,
                it won&apos;t hurt your budget.
              </p>
            </div>
            <div className="home-element1">
              <h3 className="home-header11">
                How can I start the development?
              </h3>
              <p className="home-content09">
                Just simply click the &quot;Get started&quot; on top of the page
                or directly contact us at https://facebook.com/vsodevtech 
              </p>
            </div>
            <div className="home-element2">
              <h3 className="home-header12">Do you offer hosting?</h3>
              <p className="home-content10">
                Yes, we offer wide option for your website to go online,
                depending on your preference.
              </p>
            </div>
          </div>
          <div className="home-column1">
            <div className="home-element3">
              <h3 className="home-header13">What services do you offer?</h3>
              <p className="home-content11">
                We specialize in custom software development, including web
                applications, mobile apps, desktop software, and more.
                Additionally, we provide services such as UI/UX design, quality
                assurance testing, and maintenance.
              </p>
            </div>
            <div className="home-element4">
              <h3 className="home-header14">
                <span className="home-text34">
                  How long does it take to complete a project?
                </span>
                <br className="home-text35"></br>
              </h3>
              <p className="home-content12">
                Project timelines vary depending on the scope, complexity, and
                requirements of the project. We work closely with our clients to
                establish realistic timelines and milestones, ensuring timely
                delivery without compromising quality.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-element5">
              <h3 className="home-header15">
                Can you work with existing systems or legacy code?
              </h3>
              <p className="home-content13">
                Absolutely! We have experience integrating with existing systems
                and legacy codebases. Our team conducts thorough assessments to
                understand your current setup and devise strategies for seamless
                integration or modernization.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content14">
          <div className="home-main">
            <div className="home-branding">
              <img alt="image" src="/vso.png" className="home-image3" />
              <span className="home-text36">
              Formerly Vidarsson Online, VSODEV is your innovative partner for digital solutions, delivering excellence in design and technology.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="home-links">
              <div className="home-column2">
                <span className="home-header16">Company</span>
                <div className="home-list">
                  <a onClick={() => scrollToRef(aboutRef)} className="home-navlink">
                    About
                  </a>
                  <a onClick={() => scrollToRef(servicesRef)} className="home-navlink01">
                    Services
                  </a>
                  <a  onClick={() => scrollToRef(howRef)} className="home-navlink02">
                    How
                  </a>
                  <a  onClick={() => scrollToRef(whyRef)} className="home-navlink03">
                    <span className="home-text37">Why</span>
                    <br></br>
                  </a>
                </div>
              </div>
              <div className="home-column3">
                <span className="home-header17">Extern</span>
                <div className="home-list1">
                  <a href='https://facebook.com/vsodevtech' className="home-navlink04">
                    <span>News</span>
                  </a>
                  <Link to="/" className="home-navlink08">
                    Terms
                  </Link>
                  <Link to="/" className="home-navlink09">
                    Legal
                  </Link>
                  <a href='https://m.me/vsodevtech'  className="home-navlink10">
                    Careers
                  </a>
                </div>
              </div>
              <div className="home-column4">
                <span className="home-header18">Social</span>
                <div className="home-list2">
                  <a href="https://www.linkedin.com/company/vidarsson-online" className="home-navlink11">
                   LinkedIn
                  </a>
                  <a href="https://facebook.com/vsodevtech" className="home-navlink14">
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text39">
              © 2022 vsodevtech - All rights reserved
            </span>
            <button onClick={() => scrollToRef(aboutRef)} data-role="scroll-top" className="home-button3 button">
              <img alt="image" src="/arrow.svg" className="home-image4" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="home-container2">
        </div>
      </div>
    </div>
  )
}

export default Home
